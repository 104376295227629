var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "6", sm: "6" } },
              [
                _c(
                  "b-card",
                  { staticClass: "p-4", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-body",
                      [
                        _c("img", {
                          staticClass: "navbar-brand-full",
                          attrs: {
                            src: "img/brand/thegreenshot-brand.png",
                            width: "120",
                            alt: "TheGreenShot",
                            align: "right",
                          },
                        }),
                        _c("br"),
                        _c("h2", [_vm._v(_vm._s(_vm.FormMSG(1, "Register")))]),
                        _c("p", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  102,
                                  "Free, no credit card required!"
                                )
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "icon-user" }),
                                ]),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(6, "First name"),
                              },
                              model: {
                                value: _vm.userFirstName,
                                callback: function ($$v) {
                                  _vm.userFirstName = $$v
                                },
                                expression: "userFirstName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "icon-user" }),
                                ]),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(5, "Last name"),
                              },
                              model: {
                                value: _vm.userName,
                                callback: function ($$v) {
                                  _vm.userName = $$v
                                },
                                expression: "userName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [_c("b-input-group-text", [_vm._v("@")])],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                placeholder: _vm.FormMSG(7, "Email"),
                                autocomplete: "email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "icon-speech" }),
                                ]),
                              ],
                              1
                            ),
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionsForLanguages,
                                id: "optionsForLanguages",
                              },
                              model: {
                                value: _vm.userLanguage,
                                callback: function ($$v) {
                                  _vm.userLanguage = $$v
                                },
                                expression: "userLanguage",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "success",
                              block: "",
                              disabled: this.disableSave,
                            },
                            on: { click: _vm.createAccount },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.FormMSG(8, "Create License Account"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-success",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(12, "Success!"),
                  "ok-variant": "success",
                  "ok-only": "",
                  "modal-class": "mui-animation",
                  fade: false,
                },
                on: { ok: this.callEnd },
                model: {
                  value: _vm.successModal,
                  callback: function ($$v) {
                    _vm.successModal = $$v
                  },
                  expression: "successModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        9,
                        `An email has been sent. Open it and click on the provided link to
               create your new license for TheGreenShot.`
                      )
                    ) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(428, "Error!"),
                  "ok-variant": "danger",
                  "ok-only": "",
                  "modal-class": "mui-animation",
                  fade: false,
                },
                on: { ok: this.callEnd },
                model: {
                  value: _vm.alreadyExistModal,
                  callback: function ($$v) {
                    _vm.alreadyExistModal = $$v
                  },
                  expression: "alreadyExistModal",
                },
              },
              [
                _c("h2", [
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          427,
                          `You already registered a single user free project!`
                        )
                      ) +
                      "\n\t\t\t\t\t"
                  ),
                ]),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(11, "Field validation error"),
                  "ok-variant": "danger",
                  "ok-only": "",
                  "modal-class": "mui-animation",
                  fade: false,
                },
                on: {
                  ok: function ($event) {
                    _vm.errorModal = false
                  },
                },
                model: {
                  value: _vm.errorModal,
                  callback: function ($$v) {
                    _vm.errorModal = $$v
                  },
                  expression: "errorModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        10,
                        "All fields must be filled with valid data!"
                      )
                    ) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: "Email address invalid",
                  "ok-variant": "danger",
                  "ok-only": "",
                  "modal-class": "mui-animation",
                  fade: false,
                },
                on: {
                  ok: function ($event) {
                    _vm.errorEmailModal = false
                  },
                },
                model: {
                  value: _vm.errorEmailModal,
                  callback: function ($$v) {
                    _vm.errorEmailModal = $$v
                  },
                  expression: "errorEmailModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        13,
                        "Check the email address provided, it is invalid!"
                      )
                    ) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }