<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<b-row class="justify-content-center">
				<b-col md="6" sm="6">
					<b-card no-body class="p-4">
						<b-card-body>
							<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="120" alt="TheGreenShot" align="right" />
							<br />

							<h2>{{ FormMSG(1, 'Register') }}</h2>
							<p class="text-muted">
								{{ FormMSG(102, 'Free, no credit card required!') }}
							</p>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text><i class="icon-user"></i></b-input-group-text>
								</b-input-group-prepend>
								<b-form-input type="text" v-model="userFirstName" class="form-control" :placeholder="FormMSG(6, 'First name')" />
							</b-input-group>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text><i class="icon-user"></i></b-input-group-text>
								</b-input-group-prepend>
								<b-form-input type="text" v-model="userName" class="form-control" :placeholder="FormMSG(5, 'Last name')" />
							</b-input-group>

							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text>@</b-input-group-text>
								</b-input-group-prepend>
								<b-form-input type="email" v-model="email" class="form-control" :placeholder="FormMSG(7, 'Email')" autocomplete="email" />
							</b-input-group>
							<b-input-group class="mb-3">
								<b-input-group-prepend>
									<b-input-group-text><i class="icon-speech"></i></b-input-group-text>
								</b-input-group-prepend>
								<b-form-select v-model="userLanguage" :options="optionsForLanguages" id="optionsForLanguages"></b-form-select>
							</b-input-group>

							<b-button variant="success" block :disabled="this.disableSave" @click="createAccount">{{
								FormMSG(8, 'Create License Account')
							}}</b-button>
						</b-card-body>
					</b-card>
				</b-col>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(12, 'Success!')"
					class="modal-success"
					v-model="successModal"
					@ok="this.callEnd"
					ok-variant="success"
					ok-only
					modal-class="mui-animation"
					:fade="false"
				>
					{{
						FormMSG(
							9,
							`An email has been sent. Open it and click on the provided link to
               create your new license for TheGreenShot.`
						)
					}}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(428, 'Error!')"
					class="modal-danger"
					v-model="alreadyExistModal"
					@ok="this.callEnd"
					ok-variant="danger"
					ok-only
					modal-class="mui-animation"
					:fade="false"
				>
					<h2>
						{{ FormMSG(427, `You already registered a single user free project!`) }}
					</h2>
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(11, 'Field validation error')"
					class="modal-danger"
					v-model="errorModal"
					@ok="errorModal = false"
					ok-variant="danger"
					ok-only
					modal-class="mui-animation"
					:fade="false"
				>
					{{ FormMSG(10, 'All fields must be filled with valid data!') }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					title="Email address invalid"
					class="modal-danger"
					v-model="errorEmailModal"
					@ok="errorEmailModal = false"
					ok-variant="danger"
					ok-only
					modal-class="mui-animation"
					:fade="false"
				>
					{{ FormMSG(13, 'Check the email address provided, it is invalid!') }}
				</b-modal>
			</b-row>
		</div>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment-timezone';

import languageMessages from '@/mixins/languageMessages';

import { getMenuArrayFromDb } from '@/cruds/language.crud';

const mutation_addLicenseRequest = gql`
	mutation (
		$email: String!
		$licenseOwner: String!
		$projectTitle: String!
		$language: Int!
		$timeZone: String!
		$userName: String!
		$userFirstName: String!
		$password: String!
		$userTypeForFilm: Int!
		$nextProductionDate: String!
		$budgetBracket: String!
	) {
		AddLicenseRequest(
			NewLicenseRequest: {
				email: $email
				licenseOwner: $licenseOwner
				projectTitle: $projectTitle
				language: $language
				timeZone: $timeZone
				userName: $userName
				userFirstName: $userFirstName
				password: $password
				userTypeForFilm: $userTypeForFilm
				nextProductionDate: $nextProductionDate
				budgetBracket: $budgetBracket
			}
		) {
			email
			licenseOwner
			projectTitle
			userName
			userFirstName
			xid
		}
	}
`;

export default {
	name: 'Register',
	mixins: [languageMessages],
	data() {
		return {
			successModal: false,
			errorModal: false,
			alreadyExistModal: false,
			errorEmailModal: false,
			licenseOwner: '',
			projectTitle: '',
			userName: '',
			userFirstName: '',
			userLanguage: 0,
			email: '',
			password: '',
			repeat_password: '',
			userTypeForFilm: 5,
			nextProductionDate: '2021-09-01T00:00:00+00:00',
			budgetBracket: ''
		};
	},
	computed: {
		optionsForLanguages() {
			/*
      var mnu = await getMenuArrayFromDb(1);
      console.log("in optionsForLanguages mnu:",mnu);
      return mnu; */
			// create database only in validated languages
			return this.FormMenu(1115);
		},
		optionsForUserTypeForFilm() {
			return this.FormMenu(1114);
		},
		disableSave() {
			if (this.showQuestions()) {
				if (this.licenseOwner.length == 0 || this.projectTitle.length == 0) {
					return true;
				}
			}
			if (this.userName.length == 0 || this.userFirstName.length == 0 || this.email.length == 0) {
				return true;
			}
			// check if email is valid
			var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.email)) {
				return true;
			}
			// end check if email is valid
			return false;
		}
	},
	methods: {
		showQuestions() {
			return this.userTypeForFilm == 1;
		},
		updateNextProductionDate: function ($event) {
			if (this.nextProductionDate.startsWith('T')) {
				this.nextProductionDate = $event + this.nextProductionDate;
			} else {
				this.nextProductionDate = $event + this.nextProductionDate.substring(10);
			}
		},
		callEnd() {
			console.log('in coallEnd');
			this.successModal = false;
			this.alreadyExistModal = false;
			this.$router.push('/pages/login').catch(() => {});
		},
		createAccount() {
			if (this.userTypeForFilm == 5) {
				// technician project initialise the not displayed values
				this.licenseOwner = this.userName + ' ' + this.userFirstName;
				this.projectTitle = this.FormMSG(230, 'My Project');
				this.budgetBracket = this.FormMSG(231, 'FREE');
			}
			var tzName = moment.tz.guess();
			// console.log("tzName:",tzName)
			var timezone = moment.tz(tzName).zoneAbbr();
			//console.log("timezone:",timezone)

			var req = mutation_addLicenseRequest;
			var curLanguage = this.userLanguage;

			this.$apollo
				.mutate({
					mutation: req,
					variables: {
						email: this.email,
						licenseOwner: this.licenseOwner,
						projectTitle: this.projectTitle,
						language: curLanguage,
						timeZone: timezone,
						userName: this.userName,
						userFirstName: this.userFirstName,
						password: this.password,
						userTypeForFilm: this.userTypeForFilm,
						nextProductionDate: this.nextProductionDate,
						budgetBracket: this.budgetBracket
					}
				})
				.then((result) => {
					console.log(result.data.AddLicenseRequest);
					this.successModal = true;
					//  this.$router.push("/pages/login")
				})
				.catch((error) => {
					const _t = this.FormMSG;
					if (error.networkError) {
						alert(_t(23231, 'Your computer does not seem to be connected to the internet.'));
					} else if (error.graphQLErrors.length > 0) {
						var status = error.graphQLErrors[0].status;
						var message = error.graphQLErrors[0].message;
						if (status == 427) {
							this.alreadyExistModal = true;
						} else {
							alert(_t(23232, 'There was an error. Please try again later.'));
							//console.log("error " + status + ": " + message)
							//                 console.log("erreur:",error)
						}
					} else {
						alert(_t(23233, 'There was an error. Check values and retry.'));
						console.log('unknown error: ' + JSON.stringify(error));
						console.log('erreur:', error);
					}
				});
		},
		registerNow() {
			this.$router.push('/pages/register');
		}
	}
};
</script>
